import { OriginalIngredientCategory, OriginalIngredientCategoryCollection } from 'models/entities/original-ingredient-category';

import { Condition } from './models/condition';

class Model {

  readonly condition: Condition;
  readonly collection?: OriginalIngredientCategoryCollection;
  readonly list?: OriginalIngredientCategory[];
  readonly categories?: OriginalIngredientCategory[];

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const collection = await OriginalIngredientCategoryCollection.read();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(): OriginalIngredientCategory  {
    if (!this.collection) throw new Error('collection is undefined');
    return OriginalIngredientCategory.buildNext(this.collection.documents);
  }

  add(category: OriginalIngredientCategory ): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(category));
  }

  replace(category: OriginalIngredientCategory ): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(category));
  }

  remove(category: OriginalIngredientCategory ): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(category));
  }

  private apply(collection: OriginalIngredientCategoryCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

}

export { Model };